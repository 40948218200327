<template>
  <b-card
    v-if="show === true"
    class="shadow-none"
  >
    <!-- about -->
    <h4 class="text-center mb-1">
      Catatan Internal
    </h4>

    <b-list-group class="mb-2">
      <b-list-group-item
        v-for="(data, index) in rows"
        :key="index"
        class="flex-column align-items-start"
      >
        <div class="mb-50">
          <p class="font-weight-bold mb-0">
            {{ data.created_by }}
          </p>
          <small class="text-primary">
            {{ data.created_on | formatDate }}
          </small>
        </div>
        <b-card-text>
          {{ data.catatan }}
        </b-card-text>
      </b-list-group-item>
    </b-list-group>

    <b-form @submit.prevent="saveTicket">
      <b-row>
        <b-col
          v-if="errorStat"
          cols="12"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body">
              Error Found: {{ errorMsg }}
            </div>
          </b-alert>
        </b-col>
        <b-col
          md="12"
        >
          <b-form-group
            label="Tambah Catatan"
            label-for="vi-desc"
          >
            <b-form-textarea
              id="vi-desc"
              v-model="desc"
              rows="4"
              required
              name="desc"
              placeholder="Tambah Catatan"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-button
            type="submit"
            variant="primary"
          >
            Simpan Catatan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BCardText, BForm, BFormTextarea, BFormGroup,
  BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BCardText,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
  },
  props: {
    detailProgram: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      desc: '',
      errorStat: false,
      errorMsg: '',
      rows: [],
    }
  },
  watch: {
    detailProgram() {
      this.getList()
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    saveTicket() {
      const formData = {
        data_utama_id: this.$route.params.id,
        catatan: this.desc,
      }
      this.$http.post('/clearance/catatan-kegiatan', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.desc = ''
            this.errorMsg = ''
            this.errorStat = false
            this.getList()
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    getList() {
      this.$http.get('/clearance/catatan-kegiatan', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data
          }
          this.show = true
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
